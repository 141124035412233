exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-legal-js-content-file-path-src-legal-disclosure-de-mdx": () => import("./../../../src/templates/legal.js?__contentFilePath=/opt/build/repo/src/legal/disclosure.de.mdx" /* webpackChunkName: "component---src-templates-legal-js-content-file-path-src-legal-disclosure-de-mdx" */),
  "component---src-templates-legal-js-content-file-path-src-legal-disclosure-mdx": () => import("./../../../src/templates/legal.js?__contentFilePath=/opt/build/repo/src/legal/disclosure.mdx" /* webpackChunkName: "component---src-templates-legal-js-content-file-path-src-legal-disclosure-mdx" */),
  "component---src-templates-legal-js-content-file-path-src-legal-privacy-de-mdx": () => import("./../../../src/templates/legal.js?__contentFilePath=/opt/build/repo/src/legal/privacy.de.mdx" /* webpackChunkName: "component---src-templates-legal-js-content-file-path-src-legal-privacy-de-mdx" */),
  "component---src-templates-legal-js-content-file-path-src-legal-privacy-mdx": () => import("./../../../src/templates/legal.js?__contentFilePath=/opt/build/repo/src/legal/privacy.mdx" /* webpackChunkName: "component---src-templates-legal-js-content-file-path-src-legal-privacy-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2007-12-22-zivildienstschule-ith-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2007-12-22-zivildienstschule-ith.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2007-12-22-zivildienstschule-ith-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2008-01-11-qual-der-wahl-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2008-01-11-qual-der-wahl.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2008-01-11-qual-der-wahl-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2008-05-26-ein-ruckblick-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2008-05-26-ein-ruckblick.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2008-05-26-ein-ruckblick-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2008-06-23-dungeonsdragons-4th-edition-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2008-06-23-dungeonsdragons-4th-edition.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2008-06-23-dungeonsdragons-4th-edition-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2008-11-15-macbook-pro-die-hardware-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2008-11-15-macbook-pro-die-hardware.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2008-11-15-macbook-pro-die-hardware-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2008-11-23-macbook-pro-das-betriebssystem-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2008-11-23-macbook-pro-das-betriebssystem.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2008-11-23-macbook-pro-das-betriebssystem-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2009-01-06-dungeons-dragons-4th-edition-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2009-01-06-dungeons-dragons-4th-edition.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2009-01-06-dungeons-dragons-4th-edition-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2009-02-18-kognitive-informatik-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2009-02-18-kognitive-informatik.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2009-02-18-kognitive-informatik-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2009-04-11-macbook-with-a-multiboot-system-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2009-04-11-macbook-with-a-multiboot-system.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2009-04-11-macbook-with-a-multiboot-system-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2009-05-10-about-strong-ai-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2009-05-10-about-strong-ai.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2009-05-10-about-strong-ai-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2009-06-17-some-questions-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2009-06-17-some-questions.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2009-06-17-some-questions-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2009-10-06-kognitive-informatik-die-zweite-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2009-10-06-kognitive-informatik-die-zweite.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2009-10-06-kognitive-informatik-die-zweite-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2010-02-19-how-arises-intelligent-behavior-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2010-02-19-how-arises-intelligent-behavior.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2010-02-19-how-arises-intelligent-behavior-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2010-02-24-kognitive-informatik-revolutions-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2010-02-24-kognitive-informatik-revolutions.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2010-02-24-kognitive-informatik-revolutions-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2010-03-20-play-act-and-learn-and-eating-fun-and-much-more-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2010-03-20-play-act-and-learn-and-eating-fun-and-much-more.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2010-03-20-play-act-and-learn-and-eating-fun-and-much-more-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2010-03-23-about-choice-of-profession-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2010-03-23-about-choice-of-profession.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2010-03-23-about-choice-of-profession-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2010-08-18-kognitive-informatik-bis-an-die-grenzen-und-daruber-hinaus-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2010-08-18-kognitive-informatik-bis-an-die-grenzen-und-daruber-hinaus.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2010-08-18-kognitive-informatik-bis-an-die-grenzen-und-daruber-hinaus-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2011-03-18-kognitive-informatik-auf-zum-endspurt-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2011-03-18-kognitive-informatik-auf-zum-endspurt.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2011-03-18-kognitive-informatik-auf-zum-endspurt-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2011-04-03-werewolves-free-beer-and-more-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2011-04-03-werewolves-free-beer-and-more.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2011-04-03-werewolves-free-beer-and-more-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2011-09-27-kognitive-informatik-das-finale-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2011-09-27-kognitive-informatik-das-finale.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2011-09-27-kognitive-informatik-das-finale-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2012-04-08-berlin-vs-bielefeld-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2012-04-08-berlin-vs-bielefeld.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2012-04-08-berlin-vs-bielefeld-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2012-05-05-computational-neuroscience-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2012-05-05-computational-neuroscience.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2012-05-05-computational-neuroscience-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2012-09-09-10-reasons-to-start-rock-climbing-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2012-09-09-10-reasons-to-start-rock-climbing.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2012-09-09-10-reasons-to-start-rock-climbing-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2012-12-28-computational-neuroscience-the-second-term-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2012-12-28-computational-neuroscience-the-second-term.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2012-12-28-computational-neuroscience-the-second-term-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2013-09-04-matlab-insanity-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2013-09-04-matlab-insanity.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2013-09-04-matlab-insanity-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2014-07-10-debugging-your-beliefs-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2014-07-10-debugging-your-beliefs.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2014-07-10-debugging-your-beliefs-mdx" */),
  "component---src-templates-post-js-content-file-path-src-posts-2021-02-07-a-guide-to-crosscompiling-applications-mdx": () => import("./../../../src/templates/post.js?__contentFilePath=/opt/build/repo/src/posts/2021-02-07-a-guide-to-crosscompiling-applications.mdx" /* webpackChunkName: "component---src-templates-post-js-content-file-path-src-posts-2021-02-07-a-guide-to-crosscompiling-applications-mdx" */)
}

